body, html {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: 'none';
}

.logo {
  background-color: rgba(177, 146, 116, 0.336);
  border-radius: 10px;
}

.character {
  width: 400px;
}

.card {
  background-color: white; 
  border-radius: 0.5em;
  width: 480px; 
  padding: 2rem 2rem; 
  margin-top: 8rem;
}

.join {
  cursor: pointer;
  background-color: #e98a43;
  border: 1px solid #fcaa58; 
  border-radius: .5rem; 
  padding: .75rem;
  width: 400px;
  color: white;
  font-weight: bold;
  font-size: 32px;
}

.join:hover {
  background-color: #caa12f;
  border: 1px solid #91721f; 
}

.discord {
}

.seperator {
  height: 100px;
}

@media (max-width: 575.98px) { /* Specific to this particular image */
  .logo {
    width: 300px;
  }
  .character {
    width: 250px;
  }
  .join {
    width: 300px;
  }
  .card {
    width: 380px;
    margin-top: 1em;
  }
  .discord {
    width: 250px;
  }
  .seperator {
    height: 0px;
  }
}

@media (max-height: 900px) { /* Specific to this particular image */
  .logo {
    width: 300px;
  }
  .character {
    width: 250px;
  }
  .join {
    width: 300px;
  }
  .card {
    width: 380px;
    margin-top: 1em;
  }
  .discord {
    width: 250px;
  }
  .seperator {
    height: 0px;
  }
}

@media (max-height: 1090px) { /* Specific to this particular image */
  .seperator {
    height: 0px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
